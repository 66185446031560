<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-form label-width="100px">
          <el-form-item label="排序">
            <el-input-number v-model="form.sort"></el-input-number>
          </el-form-item>
          <el-form-item label="分类">
            <el-cascader style="width: 100%"
                         v-model="cate_choose"
                         :options="cate_opt_list"
                         :props="{value:'id',label:'title'}"></el-cascader>
          </el-form-item>
          <el-form-item v-loading="themeLoading" label="专题">
            <el-select @change="themeChange" style="width: 100%" v-model="theme_val" multiple filterable allow-create
                       default-first-option placeholder="请选择专题标签">
              <el-option v-for="item in theme_opt" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="分享描述">
            <el-input v-model="form.share_desc"></el-input>
          </el-form-item>
          <el-form-item label="商品图">
            <y_upload_img v-model="form.pic"></y_upload_img>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form label-width="100px">
          <el-form-item label="售价">
            <el-input-number :disabled="form.spec" :min="0" v-model="form.fee"></el-input-number>元
            <el-input-number :disabled="form.spec" :min="0" v-model="form.credit"></el-input-number>积分
          </el-form-item>
          <el-form-item label="标价">
            <el-input-number :disabled="form.spec" :min="0" v-model="form.original_price"></el-input-number>
            虚拟销量
            <el-input-number :min="0" v-model="form.fake_sales"></el-input-number>
          </el-form-item>
          <el-form-item label="购买赠送积分">
            <el-input-number :min="0" v-model="form.buy_give_credit"></el-input-number>
          </el-form-item>
          <el-form-item label="购买赠送免单权益">
            <el-input-number :min="0" v-model="form.bug_give_card_rights"></el-input-number>天
          </el-form-item>
          <el-form-item label="购买限制">
            <role-access v-model="form.access_rights"></role-access>
          </el-form-item>
          <el-form-item label="运费模版">
            <y_choose_shipping v-model="form.shipping_id"></y_choose_shipping>
            <div class="y-desc">不选择运费模版时免运费</div>
          </el-form-item>
          <el-form-item label="上架">
            <div class="flex-def">
              <div>
                <el-switch v-model="form.status"></el-switch>
              </div>
              <div style="margin-left: 2rem">推荐
                <el-switch v-model="form.recommend"></el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button @click="submit" type="primary">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_choose_shipping from "@/components/shop/y_choose_shipping";
import RoleAccess from "@/components/roleAccess/roleAccess.vue";

export default {
  name: "basic",
  components: {
    RoleAccess,
    y_upload_img,
    y_choose_shipping
  },
  model: {
    prop: "modelVal",
    event: 'change'
  },
  props: {
    modelVal: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  watch: {
    theme_val() {
      this.$emit("theme-change", this.theme_val);
    },
    modelVal() {
      this.cate_choose = [this.modelVal.cate_parent_id, this.modelVal.cate_id]
      this.form = this.modelVal;
      this.modelVal.theme_item.forEach(item => {
        this.theme_val.push(item.goods_theme_id)
      })
      this.theme_val = this.removeDuplicate(this.theme_val);
    },
    cate_choose() {
      if (this.cate_choose.length > 1) {
        this.form.cate_id = this.cate_choose[1]
        this.form.cate_parent_id = this.cate_choose[0]
      }
    }
  },
  data() {
    return {
      form: this.modelVal,
      cate_opt_list: [],
      cate_choose: [],

      theme_val: [],
      theme_opt: [],
      themeLoading: false,

    }
  },
  mounted() {
    this.loadCateOpt();
    this.loadThemeOpt();
  },
  methods: {
    removeDuplicate(arr) {
      const newArr = []
      arr.forEach(item => {
        if (!newArr.includes(item)) {
          newArr.push(item)
        }
      })
      return newArr
    },
    submit() {
      this.$emit('theme-change', this.theme_val);
      this.$emit('form-submit');
    },
    themeChange(val) {
      val.forEach((item, key) => {
        if (typeof item === 'string') {
          this.themeLoading = true;
          this.$u.api.shop.goods.themeEdit({title: item}).then(id => {
            val[key] = id
            this.themeLoading = false;
            this.loadThemeOpt();
          })
        }
      })
    },
    loadThemeOpt() {
      this.$u.api.shop.goods.themeSearch({page: -1}).then(res => {
        this.theme_opt = res.list;
      })
    },
    loadCateOpt() {
      this.$u.api.shop.goods.cateAll().then(res => {
        this.cate_opt_list = res;
      })
    },
  }
}
</script>

<style scoped>

</style>